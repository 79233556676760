import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
    Reward,
    Cards,
    Home,
    Friends,
    Other,
    ComingSoon,
    SearchingOpponents,
    FightResult
} from '../pages';
import DailyRewards from '../pages/DailyRewards/DailyRewards';
import Game from '../pages/Game/Game';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/reward" element={<Reward />} />
      <Route path="/reward/daily" element={<DailyRewards />} />
      <Route path="/boost" element={<Cards />} />
      <Route path="/" element={<Home />} />
      <Route path="/friends" element={<Friends />} />
      <Route path="/other" element={<Other />} />
      <Route path="/fights" element={<ComingSoon />} />
      <Route path="/search-opponents" element={<SearchingOpponents />} />
      <Route path="/game" element={<Game />} />
      <Route path="/fight-result" element={<FightResult />} />
    </Routes>
  );
};

export default AppRoutes;
