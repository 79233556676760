import {urls} from '../../../constants';
import {splitApi} from '../api';
import {
  TGetRewardsPayload,
  TGetRewardsResponse,
  TUpdateDailyRewardsPayload,
  TUpdateDailyRewardsResponse,
} from './types';

export const rewardApi = splitApi.injectEndpoints({
  endpoints: build => ({
    getRewards: build.query<TGetRewardsResponse, TGetRewardsPayload>({
      query: params => ({
        method: 'GET',
        url: `${urls.getRewards}?user_tg_id=${params.user_tg_id}`,
      }),
    }),
    updateDailyReward: build.query<TUpdateDailyRewardsResponse, TUpdateDailyRewardsPayload>({
      query: body => ({
        method: 'PUT',
        body,
        url: `${urls.updateDailyReward}/${body.userId}?daily_reward=${body.daily_reward_id}`,
      }),
    }),
  }),
});
