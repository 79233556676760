import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import {styles} from './styles';
import {Styles} from '../../../types';
import {ReactSVG} from 'react-svg';
import RoadmapActiveSVG from '../../../assets/icons/roadmap-active.svg';
import RoadmapSVG from '../../../assets/icons/roadmap.svg';
import SupportSVG from '../../../assets/icons/support.svg';
import SupportActiveSVG from '../../../assets/icons/support-active.svg';

type TProps = {
  index: number;
  onClick: (index: number) => void;
  tabData: {
    title: string;
    icon: string;
  }[];
};

const getIcon = (title: string, isActive: boolean) => {
  switch (title) {
    case 'roadmap':
      return isActive ? RoadmapActiveSVG : RoadmapSVG;
    case 'support':
      return isActive ? SupportActiveSVG : SupportSVG;
    default:
      return SupportSVG;
  }
};

const Tabs = ({index, tabData, onClick}: TProps) => {
  return (
    <Box sx={styles.container}>
      {tabData.map((el, i) => (
        <Button onClick={() => onClick(i)} sx={{...styles.button, ...(index === i && styles.activeBtn)} as Styles}>
          <ReactSVG
            beforeInjection={svg => {
              svg.setAttribute('style', 'width: 24px; height: 24px; display: block; margin: auto');
            }}
            stroke="red"
            src={getIcon(el.icon, index === i)}
          />
          <Typography sx={{...styles.title, ...(index === i && styles.activeTitle)} as Styles}>{el.title}</Typography>
        </Button>
      ))}
    </Box>
  );
};

export default Tabs;
