import {Styles} from '../../../../../types';
import {colors} from '../../../../../constants';

export const styles: Styles = {
  container: {
    position: 'relative',
    width: 'fit-content',
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
  },
  t: {
    position: 'absolute',
    color: colors.white,
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '-0.41px',
  },
  t1: {
    bottom: '20%',
    right: '10%',
  },
  t2: {
    bottom: '0',
    left: '15%',
  },
  t3: {
    bottom: '44%',
    right: '20%',
  },
  t4: {
    bottom: '65%',
    right: '18%',
  },
  t5: {
    bottom: '53%',
    left: '10%',
  },
  t6: {
    bottom: '72%',
    left: '29%',
  },
  t7: {
    bottom: '83%',
    left: '42%',
  },
};
