import {useEffect, useState} from 'react';
import {EFightSearchingStatus} from '../pages/SearchingOpponents/types';
import {useFormattedFightData, useGetUserFightsDataQuery} from '.';
import {TGetFightResponse} from '../services/api/fight';

export const useCheckFightStatus = (
  activeFightId: string | null,
  userId: string | null,
  userFightData?: TGetFightResponse,
) => {
  const [searchingStatus, setSearchingStatus] = useState(EFightSearchingStatus.SEARCHIND);

  const formattedUserFightData = useFormattedFightData(userFightData?.fight, userId);

  useEffect(() => {
    if (activeFightId) {
      if (!userFightData?.is_search_fight || (formattedUserFightData && formattedUserFightData.host.score !== null)) {
        setSearchingStatus(EFightSearchingStatus.FINISHED);
      } else {
        setSearchingStatus(EFightSearchingStatus.TRUE);
      }
    } else if (userFightData) {
      if (userFightData.is_search_fight) {
        setSearchingStatus(EFightSearchingStatus.SEARCHIND);
      } else {
        setSearchingStatus(EFightSearchingStatus.FALSE);
      }
    }
  }, [userFightData, searchingStatus, formattedUserFightData]);

  return searchingStatus;
};
