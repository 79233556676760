import {transform} from 'typescript';
import {Styles} from '../../types';
import styled from 'styled-components';

export const styles: Styles = {
  animatedComponent: {
    position: 'absolute',
    zIndex: 9999,
    left: 'var(--start-pos, 0)',
    top: 'var(--start-pos-y, 0)',
    transform: 'translate(-50%, -50%)',
    animation: 'fadeInUp 1.5s ease forwards',
    '> span': {
      color: 'white',
      fontSize: '20px',
    },
  },
};

export const fadeInUpKeyframes = `
    @keyframes fadeInUp {
      0% {
        top: var(--start-pos-y, 0);
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        top: var(--end-pos-y, 0);
        opacity: 0;
      }
    }
  `;

export const Container = styled.div`
  position: relative;
`;

export const Button = styled.div`
  position: absolute;
  width: 80%;
  height: auto;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
`;
