import {TMainer} from '../types/entities';

export function addOrUpdateElements(arr1: TMainer[], arr2: TMainer[]) {
  const map = new Map(arr1.map(item => [item.id, item]));

  arr2.forEach(item => {
    if (!map.has(item.id)) {
      map.set(item.id, item);
    } else {
      const existingItem = map.get(item.id);
      if (existingItem && existingItem?.level < item.level) {
        map.set(item.id, item);
      }
    }
  });

  return Array.from(map.values());
}
