export const formattedNumber = (val: number) => new Intl.NumberFormat('en-US').format(val);
export const formattedNumberByMillion = (val: number) =>
  val < 1000000
    ? val
    : new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        .format(val / 1000000)
        .replace('.', ',') + 'M';

export const formattedNumberByСards = (val: number, minimumFractionDigits?: number) =>
  val < 1000
    ? val
    : val < 1000000
    ? new Intl.NumberFormat('en-US', {
        minimumFractionDigits: minimumFractionDigits !== undefined ? minimumFractionDigits : 1,
        maximumFractionDigits: 1,
      })
        .format(val / 1000)
        .replace('.', ',') + 'K'
    : new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        .format(val / 1000000)
        .replace('.', ',') + 'M';
