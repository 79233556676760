import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {TGlobalState} from '../types';
import {TGetReferralResponse} from '../services/api/referral/types';

type TInitialState = TGlobalState['referral'];

export const referralInitialState: TInitialState = {
  referrals: 0,
  total_earn: 0,
};

const referralSlice = createSlice({
  name: '@@referral',
  initialState: referralInitialState,
  reducers: {
    setReferralDataAction: (state, action: PayloadAction<TGetReferralResponse>) => {
      state.referrals = action.payload.referrals;
      state.total_earn = action.payload.total_earn;
    },

    resetReferralAction: () => referralInitialState,
  },
});

// actions
export const {setReferralDataAction, resetReferralAction} = referralSlice.actions;

// reducer
export const referral = referralSlice.reducer;

// selectors
export const selectReferral = (state: TGlobalState) => state.referral;
