import React, {useMemo} from 'react';
import {Container, Text} from './styles';
import {EFightSearchingStatus} from '../../types';
import {colors} from '../../../../constants';

type TProps = {
  status: EFightSearchingStatus;
};

const DescriptionBlock: React.FC<TProps> = ({status}) => {
  const descriptionData = useMemo(() => {
    if (status === EFightSearchingStatus.SEARCHIND) {
      return {
        color: colors.white,
        borderColor: colors.white,
        backgroundColor: 'transparent',
        text: 'We will notify you when we find an opponent',
      };
    }
    return {
      color: colors.white,
      borderColor: 'transparent',
      backgroundColor: colors.black_000000,
      text: 'Your opponent is here! You have 4 hours to complete fight',
    };
  }, [status]);

  return (
    <Container $backgroundColor={descriptionData.backgroundColor} $borderColor={descriptionData.borderColor}>
      <Text $color={descriptionData.color}>{descriptionData.text}</Text>
    </Container>
  );
};

export default DescriptionBlock;
