import React from 'react';
import {Container, Title} from './styles';

type TProps = {
  text: string;
};

const GradientBunner: React.FC<TProps> = ({text}) => {
  return (
    <Container>
      <Title>{text}</Title>
    </Container>
  );
};

export default GradientBunner;
