import React from 'react';
import {Box} from '@mui/material';
import {styles} from './styles';
import Onboarding3Image from '../../../../../assets/images/onboarding3.png';

type TProps = {};

const ThreeScreen = ({}: TProps) => {
  return (
    <Box sx={styles.container}>
      <img
        src={Onboarding3Image}
        alt="Image 1"
        style={{
          width: '100%',
          display: 'block',
        }}
      />
    </Box>
  );
};

export default ThreeScreen;
