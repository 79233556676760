import React from 'react';
import {Box} from '@mui/material';
import {ContentContainer, ModalContainer, styles} from './styles';

type TProps = {
  isVisible: boolean;
  handleClose: () => void;
  children: any;
};

const UsuallyModal = ({isVisible, handleClose, children}: TProps) => {
  return (
    <ModalContainer disableEscapeKeyDown open={isVisible} onClose={handleClose} style={{border: 'none'}}>
      <ContentContainer>
        <Box sx={styles.contentView}>{children}</Box>
      </ContentContainer>
    </ModalContainer>
  );
};

export default UsuallyModal;
