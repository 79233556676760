import React from 'react';
import {Box, Typography} from '@mui/material';
import {Container, styles} from './styles';
import {ReactSVG} from 'react-svg';
import {UsuallyButton} from '../..';
import CoinSVG from '../../../assets/icons/coin.svg';

type TProps = {
  value: number;
  onApprove: () => void;
};

const CoinsWithBackground = ({value, onApprove}: TProps) => {
  return (
    <Container>
      <Box sx={styles.profitPerHourInfo}>
        <Box sx={styles.iconContainer}>
          <ReactSVG
            src={CoinSVG}
            beforeInjection={svg => {
              svg.setAttribute('style', 'width: 44; height: 44; display: block; margin: auto;');
            }}
          />
        </Box>
        <Typography sx={styles.profitPerHourPrice}>{value.toFixed(0)}</Typography>
      </Box>
      <Typography sx={styles.title}>The exchange has started working for you!</Typography>
      <UsuallyButton onClick={onApprove} title="Thank you!" />
    </Container>
  );
};

export default CoinsWithBackground;
