import {TBackgroundDivProps} from './types';
import styled from 'styled-components';

export const BackgroundDiv = styled.div<TBackgroundDivProps>`
  width: 100vw;
  height: calc(100vh - 92px);
  background-image: url(${({$url}) => $url});
  background-size: 100% 100%;
  background-position: 'center';
  background-repeat: no-repeat;
`;
