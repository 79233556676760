import React, { useCallback, useEffect } from 'react';
import WebApp from '@twa-dev/sdk'
import { useLocation } from 'react-router-dom';
import { selectUser, setInitialUserData } from '../reducers/user';
import { useAddIncomeMutation, useAppDispatch, useAppSelector, useGetOrCreateUserQuery, useLazyGetOrCreateUserQuery } from '.';
import useQuery from './useQuery';

const usePageUnload = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const { data: user } = useAppSelector(selectUser);
  const [addIncome] = useAddIncomeMutation();
  const [getOrCreateUserQuery] = useLazyGetOrCreateUserQuery();
  
  const initialData = WebApp.initData;
  const referral_code = query.get('referral_code');
  const chat_id = query.get('chat_id');

  const handleClose = useCallback(async () => {
    try {
      const response = await getOrCreateUserQuery(`${initialData}&referral_code=${referral_code}&chat_id=${chat_id}`).unwrap();
      dispatch(setInitialUserData(response));
      if (user && response && user.coins_num !== response.coins_num) {
        const coins_num = user.coins_num - response.coins_num;
        await addIncome({ coins_num: parseInt(coins_num.toFixed(0), 10), id: response.id });
      }
    } catch(error) {
      console.error(error);
    }
    WebApp.showPopup(
      {
        message: 'Are you sure you want to close this window?',
        buttons: [{ id: 'close', type: 'close' }]
      }, (id) => id && WebApp.close()
    )
  }, [user]);

  useEffect(() => {
    WebApp.BackButton.isVisible = true
    WebApp.BackButton.onClick(handleClose);

    return () => {
      WebApp.BackButton.offClick(handleClose);
    }
  }, [handleClose]);
};

export default usePageUnload;
