import {combineReducers} from '@reduxjs/toolkit';
import {user} from './user';
import {splitApi, splitApiReducer} from '../services/api/api';
import {global} from './global';
import {reward} from './reward';
import {referral} from './referral';
import {fight} from './fight';
import {levels} from './levels';

export const rootReducer = combineReducers({
  user,
  global,
  reward,
  referral,
  fight,
  levels,
  [splitApi.reducerPath]: splitApiReducer,
});
