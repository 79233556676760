import {urls} from '../../../constants';
import {splitApi} from '../api';
import {IGetOrCreateUserResponse, TGetOrCreateUserPayload, TUpdateUserPayload, IUpdateUserResponse} from './types';

export const userApi = splitApi.injectEndpoints({
  endpoints: build => ({
    getOrCreateUser: build.query<IGetOrCreateUserResponse, TGetOrCreateUserPayload>({
      query: params => ({
        method: 'GET',
        url: `${urls.getOrCreateUser}?${params}`,
      }),
    }),
    updateUser: build.query<IUpdateUserResponse, TUpdateUserPayload>({
      query: body => ({
        method: 'PUT',
        body,
        url: `${urls.updateUser}`,
      }),
    }),
  }),
});
