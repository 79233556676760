import React, {useCallback, useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import {ReactSVG} from 'react-svg';
import {UsuallyButton} from '../..';
import CoinSVG from '../../../assets/icons/coin.svg';
import SilverCoinSVG from '../../../assets/icons/silver-coin.svg';
import {TMainer} from '../../../types/entities';

type TProps = {
  mainer: TMainer;
  coins: number;
  onApprove: (mainer: TMainer) => void;
};

const SkillUpgrade = ({mainer, coins, onApprove}: TProps) => {
  const profitCount = useMemo(() => {
    return mainer?.level === 0 ? mainer.earnings_h : mainer.earnings_h * 2;
  }, [mainer]);

  const profitPrice = useMemo(() => {
    return mainer.level === 0 ? mainer.price : mainer.price * 3;
  }, [mainer]);

  const handleClick = useCallback(() => {
    onApprove(mainer);
  }, [mainer, onApprove]);

  const isDisable = useMemo(() => {
    return coins < profitPrice;
  }, [coins, profitPrice]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.infoView}>
        <Typography sx={styles.productTitle}>{mainer.name}</Typography>
        <Typography sx={styles.description}>{mainer.description}</Typography>
      </Box>
      <Typography sx={styles.profitPerHour}>Profit per hour</Typography>

      <Box sx={styles.profitPerHourInfo}>
        <Box sx={styles.iconContainer}>
          <ReactSVG
            src={CoinSVG}
            beforeInjection={svg => {
              svg.setAttribute('style', 'width: 16px; height: 16px; display: block; margin: auto;');
            }}
          />
        </Box>
        <Typography sx={styles.profitPerHourPrice}>+{profitCount}</Typography>
      </Box>
      <Box sx={styles.profitPerHourView}>
        <ReactSVG
          src={isDisable ? SilverCoinSVG : CoinSVG}
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 24px; height: 24px; display: block; margin: auto;');
          }}
        />
        <Typography sx={styles.coins}>{profitPrice}</Typography>
      </Box>
      <UsuallyButton disable={isDisable} onClick={handleClick} title={isDisable ? 'Insufficient funds' : 'Get it'} />
    </Box>
  );
};

export default SkillUpgrade;
