import {LinearProgress, linearProgressClasses, styled} from '@mui/material';
import {colors} from '../../../constants';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 8,
  marginLeft: '14px',
  width: 'calc(100% - 28px)',
  borderRadius: 5,
  zIndex: 9,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colors.gray_272A2F,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(90deg,#3F25A4 50%, #B822BB 100%)',
  },
}));

export default BorderLinearProgress;
