import {Styles} from '../../../types';
import {colors} from '../../../constants';

export const styles: Styles = {
  container: {
    paddingTop: '16px',
    marginBottom: '23px',
  },
  title: {
    color: colors.white,
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '700',
  },
};
