import {Styles} from '../../../types';
import {colors} from '../../../constants';

export const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background,
    height: '100vh',
  },
  title: {
    color: colors.white,
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '-0.41px',
    marginBottom: '28px',
  },
  description: {
    color: colors.white,
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '22px',
    letterSpacing: '-0.41px',
  },
};
