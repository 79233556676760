import {Styles} from '../../../types';

export const styles: Styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '8px',
    paddingBottom: '100px',
    width: 'calc(100% - 32px)',
  },
};
