import { useCallback, useEffect, useRef } from 'react';
import { useLazyGetReferralQuery, useAddIncomeMutation } from '../hooks';
import { useAppDispatch, useAppSelector } from '../hooks';
import { calculateNewCoinsFromBackground } from '../helpers';
import { selectUser } from '../reducers/user';
import { selectReferral } from '../reducers/referral';

const useBackgroundFarming = () => {
  const dispatch = useAppDispatch();
  const { data: user, backgroundMining, lastCoinUpdate, initialUserData } = useAppSelector(selectUser);
  const { total_earn: referralTotalEarn } = useAppSelector(selectReferral);
  const [addIncome] = useAddIncomeMutation();
  const [getReferralQuery] = useLazyGetReferralQuery();
  const hasExecutedRef = useRef(false);

  const getBackgroundFarming = useCallback(async () => {
    try {
      if (user?.id && initialUserData) {
        const { server_time } = initialUserData;

        if (server_time && lastCoinUpdate && backgroundMining) {
          const backgroundCoinsNew = calculateNewCoinsFromBackground(server_time, lastCoinUpdate, backgroundMining);

          if (backgroundCoinsNew && backgroundCoinsNew >= 1) {
            await addIncome({ coins_num: parseInt(backgroundCoinsNew.toFixed(0), 10), id: user.id });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [user, initialUserData, lastCoinUpdate, backgroundMining, addIncome]);

  useEffect(() => {
    if (initialUserData && user && lastCoinUpdate && !hasExecutedRef.current) {
      getBackgroundFarming();
      hasExecutedRef.current = true;
    }
  }, [initialUserData, lastCoinUpdate]);
};

export default useBackgroundFarming;
