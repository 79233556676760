import styled from 'styled-components';
import {TInfoBlock} from './types';
import {colors} from '../../../constants';

export const ContainerInfo = styled.div`
  height: 56px;
  border-radius: 42px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: 'center';
  padding-bottom: 8px;
  padding-top: 10px;
  box-sizing: border-box;
`;

export const BlurContainer = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  height: 56px;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  border-radius: 42px;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 42px;
    box-shadow: inset 0 4px 4px 0 rgba(255, 255, 255, 0.15), inset 0 0 51.5px rgba(255, 255, 255, 0.05);
    pointer-events: none;
    z-index: -1;
  }
`;

export const ItemContainer = styled.div<TInfoBlock>`
  display: flex;
  flex: 1;
  z-index: 10;
  justify-content: center;
  border-left: ${props => (props.$isCenter ? '1px solid rgba(255, 255, 255, 0.2)' : 'none')};
  border-right: ${props => (props.$isCenter ? '1px solid rgba(255, 255, 255, 0.2)' : 'none')};
`;

export const ItemContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 0 2px 0;
`;
export const Title = styled.span`
  color: ${colors.white};
  font-size: 10px;
  text-align: center;
`;

export const ItemValue = styled.span`
  font-weight: 700;
  font-size: 12px;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.img`
  width: 16px;
  height: 16px;
`;
