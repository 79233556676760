import {useEffect, useState} from 'react';
import {TGetFightResponse} from '../services/api/fight';
import {TUser} from '../types/entities';
import {EFightStatus, TFightData} from '../types/entities/fight';

interface FormattedFightData {
  opponent: {
    id: string;
    first_name: string;
    last_name: string;
    score: number;
  };
  host: {
    id: string;
    first_name: string;
    last_name: string;
    score: number;
  };
  status: EFightStatus;
  bet: number;
  id: string;
}

const useFormattedFightData = (fightData: TFightData | undefined | null, userId: string | null) => {
  const [formattedData, setFormattedData] = useState<FormattedFightData | null>(null);

  useEffect(() => {
    if (fightData && fightData && userId) {
      const {opponent, fight_host, opponent_score, fight_host_score, status, bet, id} = fightData;
      const formatData = () => {
        const opponentData =
          opponent.id !== userId ? {...opponent, score: opponent_score} : {...fight_host, score: fight_host_score};
        const hostData =
          fight_host.id === userId ? {...fight_host, score: fight_host_score} : {...opponent, score: opponent_score};

        return {
          opponent: opponentData,
          host: hostData,
          status,
          bet,
          id,
        };
      };

      setFormattedData(formatData());
    } else {
      setFormattedData(null);
    }
  }, [fightData, userId]);

  return formattedData;
};

export default useFormattedFightData;
