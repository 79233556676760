import {createSlice} from '@reduxjs/toolkit';
import {TGlobalState} from '../types/reducers';
import {selectUser} from './user';

type TInitialState = TGlobalState['reward'];

export const rewardInitialState: TInitialState = {
  data: {
    daily_rewards: {
      daily_rewards: [],
      user_reward_data: {
        daily_reward: null,
        timestamp: '',
        user: null,
      },
    },
    server_time: null,
  },
  currentDay: null,
  index: 0,
  isGetReward: false,
};

const rewardSlice = createSlice({
  name: '@@reward',
  initialState: rewardInitialState,
  reducers: {
    setRewardAction: (state, action) => {
      state.data = action.payload;
    },
    setCurrentUserRewardAction: (state, action) => {
      state.data.daily_rewards.user_reward_data = action.payload;
    },
    setRewardData: (state, action) => {
      state.currentDay = action.payload.currentDay;
      state.index = action.payload.index;
      state.isGetReward = action.payload.isGetReward;
    },
    resetRewardAction: () => rewardInitialState,
  },
});

// actions
export const {setRewardAction, resetRewardAction, setRewardData, setCurrentUserRewardAction} = rewardSlice.actions;

// reducer
export const reward = rewardSlice.reducer;

// selectors
export const selectReward = (state: TGlobalState) => state.reward;

export const fetchAndSetRewardAction = () => (dispatch: any, getState: any) => {
  const state = getState();
  const userData = selectUser(state);
  const {
    data: {daily_rewards},
  } = selectReward(state);
  if (userData && userData.data) {
    let index;
    let currentDay;
    let isGetReward;
    if (!daily_rewards.user_reward_data.daily_reward) {
      index = 0;
      currentDay = daily_rewards.daily_rewards[0];
      isGetReward = true;
    } else {
      const days = calculateDiffDate(userData.data.server_time, daily_rewards.user_reward_data.timestamp);
      const currentIndex = daily_rewards.daily_rewards.findIndex(
        el => el.id === daily_rewards.user_reward_data.daily_reward,
      );
      if (days <= 0) {
        index = currentIndex;
        currentDay = daily_rewards.daily_rewards[currentIndex];
        isGetReward = false;
      } else if (days > 1) {
        index = 0;
        currentDay = daily_rewards.daily_rewards[0];
        isGetReward = true;
      } else if (currentIndex !== -1) {
        const newIndex = currentIndex + 1 < daily_rewards.daily_rewards.length ? currentIndex + 1 : 0;
        index = newIndex;
        currentDay = daily_rewards.daily_rewards[newIndex];
        isGetReward = true;
      } else {
        index = 0;
        currentDay = daily_rewards.daily_rewards[0];
        isGetReward = true;
      }
    }
    dispatch(setRewardData({index, currentDay, isGetReward}));
  }
};

function resetTime(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
function calculateDiffDate(date1: string, date2: string) {
  const resetDate1: any = resetTime(new Date(date1));
  const resetDate2: any = resetTime(new Date(date2));

  const differenceInMilliseconds = resetDate1 - resetDate2;

  const millisecondsInOneDay = 24 * 60 * 60 * 1000;
  const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

  return differenceInDays;
}
