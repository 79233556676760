import {urls} from '../../../constants';
import {splitApi} from '../api';
import {TGetReferralPayload, TGetReferralResponse} from './types';

export const referralApi = splitApi.injectEndpoints({
  endpoints: build => ({
    getReferral: build.query<TGetReferralResponse, TGetReferralPayload>({
      query: params => ({
        method: 'GET',
        url: `${urls.getReferral}/${params.user_id}`,
      }),
    }),
  }),
});
