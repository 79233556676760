export type TFightData = {
  id: string;
  fight_host: TFightUnitData;
  opponent: TFightUnitData;
  bet: number;
  status: EFightStatus;
  fight_host_score: number;
  opponent_score: number;
  end_at: string;
};

export type TFightUnitData = {
  id: string;
  first_name: string;
  last_name: string;
};

export enum EFightStatus {
  NEW = 'New',
  ACTIVE = 'Active',
  FINISH = 'Finished',
}

export type TTupData = {
  timeTup: number | null;
  amount: number | null;
};
