import {colors} from '../../../../../constants';
import {Styles} from '../../../../../types';

export const styles: Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  circleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  circle: {
    width: '11px',
    height: '11px',
    borderRadius: '11px',
    backgroundColor: '#464646',
    marginLeft: '8px',
    marginRight: '8px',
  },
  active: {
    backgroundColor: colors.royal_purple_3E25A3,
  },
  btnView: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
  },
  btnStyle: {
    maxWidth: '71px',
  },
};
