import {Styles} from '../../../types';
import {colors} from '../../../constants';

export const styles: Styles = {
  container: {
    width: 'calc(100% - 40px)',
    marginLeft: '20px',
  },
  infoView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 32px)',
    marginLeft: '16px',
    marginBottom: '8px',
  },
  productTitle: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '-0.41px',
    color: colors.white,
    marginBottom: '16px',
  },
  description: {
    color: colors.white,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.41px',
    marginBottom: '8px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  profitPerHourView: {
    backgroundColor: colors.gray_3F3F3F,
    display: 'inline-flex',
    borderRadius: '8px',
    paddingTop: '7px',
    paddingBottom: '7px',
    paddingLeft: '13.5px',
    paddingRight: '13.5px',
    alignItems: 'center',
    marginBottom: '23px',
  },
  profitPerHourInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
  },
  profitPerHourPrice: {
    fontSize: '12px',
    lineHeight: '22px',
    color: colors.white,
    fontWeight: '700',
    letterSpacing: '-0.41px',
  },
  profitPerHour: {
    fontSize: '10px',
    lineHeight: '22px',
    color: colors.white,
    letterSpacing: '-0.41px',
  },
  coins: {
    fontSize: '20',
    lineHeight: '22px',
    color: colors.white,
    fontWeight: '700',
    marginLeft: '1px',
    letterSpacing: '-0.41px',
  },
};
