import React, {useCallback, useEffect} from 'react';
import {useAppDispatch, useAppSelector, useLazyGetRewardsQuery} from '../../hooks';
import {selectUser, updateUserAction} from '../../reducers/user';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import RowItem from './components/RowItem/RowItem';
import {useNavigate} from 'react-router-dom';
import {fetchAndSetRewardAction, selectReward, setRewardAction} from '../../reducers/reward';
import {Loader} from '../../components';

const Reward = () => {
  const {data: user} = useAppSelector(selectUser);
  const {currentDay, isGetReward} = useAppSelector(selectReward);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getRewardsQuery, {isLoading}] = useLazyGetRewardsQuery();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (user) {
      try {
        const {data} = await getRewardsQuery({user_tg_id: user.user_tg_id});
        if (data) {
          dispatch(updateUserAction({server_time: data.server_time}));
          dispatch(setRewardAction(data));
          dispatch(fetchAndSetRewardAction());
        }
      } catch (error) {}
    }
  };

  if (!user) {
    return <></>;
  }

  const handlePressInActive = useCallback(() => {
    navigate('/coming-soon');
  }, []);

  const handlePressDaily = useCallback(() => {
    navigate('/reward/daily');
  }, []);

  const handlePressInviteFriends = useCallback(() => {
    navigate('/friends');
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={styles.container}>
          <Typography sx={styles.title}>Rewards</Typography>
          <Typography sx={styles.description}>
            Participate in special activities and get rewards. To get reward complete tasks from our partners.
          </Typography>
          <Box>
            <Typography sx={styles.typographyDaily}>Daily tasks</Typography>
            <RowItem
              title={'Daily rewards'}
              icon="Calendar"
              coins={isGetReward && currentDay ? currentDay.coins_number : undefined}
              onClick={handlePressDaily}
            />
            <Typography sx={styles.typographyTasks}>List of tasks</Typography>
            <RowItem title={'Invite 5 friends'} icon="Sharing" coins={50000} onClick={handlePressInviteFriends} />
            <RowItem title={'Join Telegram'} icon="Cursor" disabled coins={10000} onClick={handlePressInActive} />
            <RowItem title={'Claim 10,000 coins'} icon="Hoops" disabled coins={2000} onClick={handlePressInActive} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Reward;
