import React, {useMemo} from 'react';
import {
  Container,
  InfoContainer,
  Initial,
  Points,
  PointsContainer,
  Status,
  StatusContainer,
  TopSideContainer,
} from './styles';
import {AvatarContainer} from '../../../../components';
import {EStatusFightAvatar} from '../../../../components/layout/AvatarContainer/types';
import {colors} from '../../../../constants';
import {EFightStatus} from '../../../../types/entities/fight';
import {ReactSVG} from 'react-svg';
import coin from '../../../../assets/icons/coin.svg';

type TProps = {
  url: string;
  fio: string;
  fightStatus: EFightStatus;
  points: number | null;
};

const UserFightInfoItem: React.FC<TProps> = ({url, fio, points, fightStatus}) => {
  const badgeData = useMemo(() => {
    if (points !== null || fightStatus === EFightStatus.FINISH) {
      return {
        color: colors.yellow_F5CD40,
        label: 'Fight complete',
      };
    }
    return {
      color: colors.red_BF2218,
      label: 'Waiting',
    };
  }, [fightStatus, points]);
  return (
    <Container>
      <AvatarContainer $size={'72px'} status={EStatusFightAvatar.NOT_AVAILABLE} url={url} />
      <InfoContainer>
        <TopSideContainer>
          <Initial>{fio}</Initial>
          <StatusContainer $color={badgeData.color}>
            <Status $color={badgeData.color}>{badgeData.label}</Status>
          </StatusContainer>
        </TopSideContainer>
        <PointsContainer>
          {points !== null && points > 0 && (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={coin} alt="coin" style={{width: 24, height: 24}} />
            </div>
          )}
          <Points>{points !== null && points > 0 ? `+${points}` : ''}</Points>
        </PointsContainer>
      </InfoContainer>
    </Container>
  );
};

export default UserFightInfoItem;
