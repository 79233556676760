import styled from 'styled-components';
import {TContainerStyle} from './types';
import {colors} from '../../../constants';

export const Container = styled.div<TContainerStyle>`
  width: ${({$size}) => $size};
`;

export const ImageContainer = styled.div<TContainerStyle>`
  width: ${({$size}) => $size};
  height: ${({$size}) => $size};
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const StatusContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  height: 24px;
  width: 24px;
`;

export const Title = styled.p`
  color: ${colors.white};
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  text-overflow: ellipsis;
`;
