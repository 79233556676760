import styled from 'styled-components';
import {colors} from '../../../../constants';
export const Container = styled.div`
  padding: 24px 16px 28px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${colors.white};
  margin-bottom: 15px;
  font-weight: 700;
  text-align: center;
`;

export const Description = styled.p`
  color: ${colors.gray_A4A4A4};
  font-size: 12px;
  text-align: center;
  margin-bottom: 24px;
`;

export const AcceptButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 60vw;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
