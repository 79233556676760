import {Styles} from '../../../types';

export const styles: Styles = {
  container: {
    marginTop: '23px',
    marginBottom: '56px',
    display: 'flex',
    justifyContent: 'center',
  },
};
