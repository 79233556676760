import styled from 'styled-components';
import {motion} from 'framer-motion';
import {Styles} from '../../../../types';

export type TEggContainer = {
  $width: string;
  $height: string;
  startXY: {x: number; y: number};
};

export const Container = styled(motion.div)<TEggContainer>`
  display: flex;
  width: ${({$width}) => $width};
  height: ${({$height}) => $height};
  position: absolute;
`;
