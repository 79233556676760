import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {TGlobalState} from '../types/reducers';
import {TTupData} from '../types/entities/fight';

type TInitialState = TGlobalState['fight'];

export const userInitialState: TInitialState = {
  lastFightId: null,
  tupData: {
    timeTup: null,
    amount: null,
  },
};

const fightSlice = createSlice({
  name: '@@fight',
  initialState: userInitialState,
  reducers: {
    setLastFightAction: (state, action: PayloadAction<string | null>) => {
      state.lastFightId = action.payload;
    },
    setTupData: (state, action: PayloadAction<TTupData>) => {
      state.tupData = action.payload;
    },
    resetFightAction: () => userInitialState,
  },
});

// actions
export const {setLastFightAction, resetFightAction, setTupData} = fightSlice.actions;

// reducer
export const fight = fightSlice.reducer;

// selectors
export const selectFight = (state: TGlobalState) => state.fight;
