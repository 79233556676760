import React from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';

type TProps = {
  userName: string;
};

const ProfileHeader = ({userName}: TProps) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}> {userName ? `${userName} ` : ''}</Typography>
    </Box>
  );
};

export default ProfileHeader;
