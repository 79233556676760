import {Styles} from '../../../../../types';

export const styles: Styles = {
  container: {
    position: 'relative',
    display: 'inline-block',
    width: 'calc(100% - 160px)',
    marginTop: '37px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '100px',
      background: 'linear-gradient(to bottom, rgba(30, 30, 30, 0) 0%, rgba(30, 30, 30, 1) 100%)',
      pointerEvents: 'none',
    },
  },
};
