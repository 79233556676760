import {Styles} from '../../../types';
import {colors} from '../../../constants';

export const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: {
    fontWeight: '700',
    fontSize: 24,
    color: colors.white,
  },
};
