export const colors = {
  background: '#1E1E1E',
  gray_272A2F: '#272A2F',
  gray_3F3F3F: '#3F3F3F',
  gray_AFAFAF: '#AFAFAF',
  gray_545454: '#545454',
  gray_A4A4A4: '#A4A4A4',
  black_1D1D1D: '#1D1D1D',
  black_000000: '#000000',
  purple_9B82FF: '#9B82FF',
  purple_4C1881: '#4C1881',
  blue_48C3C3: '#48C3C3',
  royal_purple_3E25A3: '#3E25A3',
  yellow_F5CD40: '#F5CD40',
  white: '#FFFFFF',
  red_B30D02: '#B30D02',
  red_BF2218: '#BF2218',
  red_A92E2A: '#A92E2A',
  red_6F1812: '#6F1812',
  main_gradient_first_color_A92E2A: '#A92E2A',
  main_gradient_second_color_4C1881: '#4C1881',
};
