import React from 'react';
import {
  BlurContainer,
  ContainerInfo,
  ImageContainer,
  ItemContainer,
  ItemContentContainer,
  ItemValue,
  Title,
} from './styles';
import {TProps} from './types';
import {assets} from '../../../assets';
import {formattedNumberByСards} from '../../../helpers';
import { useAppSelector } from '../../../hooks';
import { selectLevels } from '../../../reducers/levels';

const BriefCoinInfo: React.FC<TProps> = ({userLvl, backgroundMining, profitPerTap = 1}) => {
  const levels = useAppSelector(selectLevels);

  return (
    <BlurContainer>
      <ContainerInfo>
        <ItemContainer>
          <ItemContentContainer>
            <Title>Profit per tap</Title>
            <ItemValue>
              <ImageContainer src={assets.coin} alt="coin" />+{profitPerTap}
            </ItemValue>
          </ItemContentContainer>
        </ItemContainer>
        <ItemContainer $isCenter>
          <ItemContentContainer>
            <Title>Your level</Title>
            <ItemValue>{userLvl}/{levels.length}</ItemValue>
          </ItemContentContainer>
        </ItemContainer>
        <ItemContainer>
          <ItemContentContainer>
            <Title>Profit per hour</Title>
            <ItemValue>
              <ImageContainer src={assets.coin} alt="coin" />+
              {formattedNumberByСards(Number((backgroundMining * 3600).toFixed(0)))}
            </ItemValue>
          </ItemContentContainer>
        </ItemContainer>
      </ContainerInfo>
    </BlurContainer>
  );
};

export default BriefCoinInfo;
