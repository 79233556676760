import React, {useState} from 'react';
import {Box} from '@mui/material';
import {Button, Container, fadeInUpKeyframes, styles} from './styles';
import {Global, css} from '@emotion/react';
import {assets} from '../../assets';
import SemicircleProgressDiagram from '../dataview/SemicircleProgressDiagram/SemicircleDiagram';
import {TUser} from '../../types/entities';

type TProps = {
  onClick: () => void;
  user: TUser;
};

const CoinClicker: React.FC<TProps> = ({onClick, user}) => {
  const [isPressed, setIsPressed] = useState(false);
  const [animatedComponents, setAnimatedComponents] = useState<
    Array<{id: string | number; posX: number; posY: number}>
  >([]);

  const handlePress = () => {
    if (!isPressed) {
      setIsPressed(true);
    }
  };

  const handleTouchStart = () => {
    setIsPressed(true);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isPressed) {
      const touches = e.changedTouches;
      for (let i = 0; i < touches.length; i++) {
        const touch = touches[i];
        const {left, top} = e.currentTarget.getBoundingClientRect();
        const {clientX, clientY} = touch;
        const posX = clientX - left;
        const posY = clientY - top;

        const animatedComponent = animatedComponents.find(comp => comp.id === touch.identifier);
        if (animatedComponent) {
          animatedComponent.posX = posX;
          animatedComponent.posY = posY;
          setAnimatedComponents([...animatedComponents]);
        }
      }
    }
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    setIsPressed(false);
    if (onClick) {
      onClick();
    }

    const touches = e.changedTouches;
    for (let i = 0; i < touches.length; i++) {
      const touch = touches[i];
      const {left, top} = e.currentTarget.getBoundingClientRect();
      const {clientX, clientY} = touch;
      const posX = clientX - left;
      const posY = clientY - top;
      const newId = Date.now();
      setAnimatedComponents(prev => [...prev, {id: newId, posX, posY}]);
    }
  };

  const removeAnimatedComponent = (id: string | number) => {
    setAnimatedComponents(prev => prev.filter(item => item.id !== id));
  };

  return (
    <>
      <Global
        styles={css`
          ${fadeInUpKeyframes}
        `}
      />
      <Container>
        <SemicircleProgressDiagram user={user} />
        <Button
          onMouseDown={handlePress}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}>
          {animatedComponents.map(({id, posX, posY}) => (
            <AnimatedComponent
              key={id}
              id={id}
              posX={posX}
              posY={posY}
              removeComponent={() => removeAnimatedComponent(id)}
            />
          ))}
          <img
            src={assets.dinosaur}
            alt="coin"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </Button>
      </Container>
    </>
  );
};

export default CoinClicker;

const AnimatedComponent = ({
  id,
  posX,
  posY,
  removeComponent,
}: {
  id: string | number;
  posX: number;
  posY: number;
  removeComponent: () => void;
}) => {
  const endPosY = posY - 150;

  const style = {
    '--start-pos-y': `${posY}px`,
    '--end-pos-y': `${endPosY}px`,
    left: `${posX}px`,
  };

  return (
    <Box sx={styles.animatedComponent} style={style} onAnimationEnd={removeComponent}>
      <span>+1</span>
    </Box>
  );
};
