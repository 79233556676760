import styled from 'styled-components';
import {colors} from '../../../../constants';

type TStatus = {
  $color: string;
};
export const Container = styled.div`
  height: 89px;
  background-color: ${colors.black_000000};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 8px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
export const TopSideContainer = styled.div`
  align-self: flex-start;
`;

export const Initial = styled.p`
  color: ${colors.white};
  font-size: 12px;
  font-weight: 700;
`;

export const StatusContainer = styled.div<TStatus>`
  border: ${({$color}) => `1px solid ${$color}`};
  border-radius: 8px;
  display: flex;
  height: 20px;
  box-sizing: border-box;
  padding-inline: 9px;
  justify-content: center;
  align-items: center;
`;

export const Status = styled.span<TStatus>`
  color: ${({$color}) => $color};
  font-size: 8px;
  font-weight: 600;
`;

export const PointsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const Points = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
