import React, { useCallback, useState } from 'react';
import { Box, BottomNavigation } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { bottomNavigationStyle, linkStyle, styles } from '../styles';
import { HomeButton, NavigationButton, UsuallyModal, CoinsWithBackground, DeviceCheck, Onboarding } from '../components';

import usePageUnload from '../hooks/usePageUnload';
import useCheckLevels from '../hooks/useCheckLevels';
import useBackgroundFarming from '../hooks/useBackgroundFarming';
import useGetInitialData from '../hooks/useGetInitialData';
import useBackgroundMining from '../hooks/useBackgroundMining';
import { useAppDispatch, useAppSelector } from '../hooks';
import { selectGlobal, setFirstOpenAppAction } from '../reducers/global';
import { isMobile } from 'react-device-detect';
import { resetUserAction, selectUser, setCoinsAction, setNewCoins } from '../reducers/user';

const MainLayout = ({ children }: any) => {
  usePageUnload();
  useCheckLevels();
  useGetInitialData();
  useBackgroundFarming();
  useBackgroundMining();

  const dispatch = useAppDispatch();
  const { newCoins } = useAppSelector(selectUser);
  const [route, setRoute] = useState<'Mine' | 'Friends' | 'Reward' | 'Boost' | 'Other'>('Mine');
  const { firstOpenApp, isLoading } = useAppSelector(selectGlobal);

  const handleRoutePress = useCallback((route: 'Mine' | 'Friends' | 'Reward' | 'Boost' | 'Other') => {
    setRoute(route);
  }, []);

  const handleApprove = useCallback(() => {
    dispatch(setCoinsAction(newCoins));
    dispatch(setNewCoins(null));
  }, [newCoins]);

  const handleCloseOnboarding = useCallback(() => {
    dispatch(setFirstOpenAppAction(false));
  }, []);

  if (!isMobile) {
    return <DeviceCheck />;
  }

  if (firstOpenApp) {
    dispatch(resetUserAction());
  }

  if (isLoading || firstOpenApp) {
    return <Onboarding onClose={handleCloseOnboarding} loading={isLoading} firstOpenApp={firstOpenApp} />;
  }

  return (
    <>
      <Box>
        <div className="content">
          {children}
        </div>
        <Box sx={styles.bottomNavigationContainer}>
          <BottomNavigation style={bottomNavigationStyle}>
            <Link onClick={() => handleRoutePress('Reward')} style={linkStyle} to="/reward">
              <NavigationButton isActive={route === 'Reward'} title="Reward" />
            </Link>
            <Link onClick={() => handleRoutePress('Boost')} style={linkStyle} to="/boost">
              <NavigationButton isActive={route === 'Boost'} title="Boost" />
            </Link>
            <Link onClick={() => handleRoutePress('Mine')} style={linkStyle} to="/">
              <HomeButton onClick={() => handleRoutePress('Mine')} />
            </Link>
            <Link onClick={() => handleRoutePress('Friends')} style={linkStyle} to="/friends">
              <NavigationButton isActive={route === 'Friends'} title="Friends" />
            </Link>
            <Link onClick={() => handleRoutePress('Other')} style={linkStyle} to="/other">
              <NavigationButton isActive={route === 'Other'} title="Other" />
            </Link>
          </BottomNavigation>
        </Box>
      </Box>
      <UsuallyModal isVisible={!!newCoins} handleClose={handleApprove}>
        {newCoins ? <CoinsWithBackground onApprove={handleApprove} value={newCoins} /> : null}
      </UsuallyModal>
    </>
  );
};

export default MainLayout;
