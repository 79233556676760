import React from 'react';
import {FloatingNumber} from './styles';
import {TFlyingNumberPosition} from '../../types';

type TProps = TFlyingNumberPosition & {
  setNumberPositions: () => void;
};

const FlyingNumber: React.FC<TProps> = ({setNumberPositions, x, y, id, amount, endY}) => {
  return (
    <FloatingNumber
      key={id}
      initial={{opacity: 1, y, x}}
      animate={{opacity: 0, y: -endY}}
      transition={{duration: 2}}
      onAnimationComplete={setNumberPositions}>
      {amount}
    </FloatingNumber>
  );
};

export default FlyingNumber;
