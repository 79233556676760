import WebApp from "@twa-dev/sdk";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

import {
  useLazyGetOrCreateUserQuery,
  useLazyGetReferralQuery,
  useLazyUpdateUserQuery,
  useAddIncomeMutation,
  useAppDispatch,
  useAppSelector
} from ".";
import { addOrUpdateElements, calculateNewCoinsFromBackground } from "../helpers";
import { selectGlobal, setIsLoadingAction } from "../reducers/global";
import { resetReferralAction, selectReferral, setReferralDataAction } from "../reducers/referral";
import { resetUserAction, selectUser, setInitialUserData, setNewCoins, setUserDataAction, updateBackgroundMining, updateUserAction } from "../reducers/user";
import useQuery from "./useQuery";

const useGetInitialData = () => {
    const [getOrCreateUserQuery] = useLazyGetOrCreateUserQuery();
    const [updateUserQuery] = useLazyUpdateUserQuery();
    const [getReferralQuery] = useLazyGetReferralQuery();
    const [addIncome] = useAddIncomeMutation();

    const dispatch = useAppDispatch();
    const { data: user, backgroundMining, lastCoinUpdate } = useAppSelector(selectUser);
    const { total_earn: referralTotalEarn } = useAppSelector(selectReferral);
    const query = useQuery();

    const getInitialData = async () => {
      const initialData = WebApp.initData;
      const referral_code = query.get('referral_code');
      const chat_id = query.get('chat_id');

      try {
        const response = await getOrCreateUserQuery(`${initialData}&referral_code=${referral_code}&chat_id=${chat_id}`).unwrap();
        // const response = await getOrCreateUserQuery(
        //   `query_id=AAGS5lEUAAAAAJLmURSq-1hY&user=%7B%22id%22%3A340911762%2C%22first_name%22%3A%22%D0%90%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22af_andrew%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1719852758&hash=a0357bae5df0a05a96653c8ec38c259bac112eed7c85bf1cd3592b8768098556&referral_code=${referral_code}`,
        // ).unwrap(); //Andrei

        // const response = await getOrCreateUserQuery(
        //   `query_id=AAG_b3FTAAAAAL9vcVObiG_D&user=%7B%22id%22%3A1399943103%2C%22first_name%22%3A%22Victoriya%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SViktoriyaS%22%2C%22language_code%22%3A%22uk%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1722239699&hash=443041127359daa1a65135580575a9e37273b6f94ad152c00c328e32743be153&referral_code=EGSSwNQ7agG8MCKjBdUZMgveWAs8antq&chat_id=672835105`,
        // ).unwrap(); //Vika

        const {server_time} = response;

        const {data} = await getReferralQuery({user_id: response.id});

        if (response && response.user_tg_id && response.id) {
          dispatch(resetUserAction());
          dispatch(setUserDataAction(response));
          dispatch(setInitialUserData(response));
          dispatch(updateBackgroundMining());
          if (data) {
            dispatch(setReferralDataAction(data));
          } else {
            dispatch(resetReferralAction());
          }
          const updateCardArray = addOrUpdateElements(user?.cards ?? [], response.cards);
          dispatch(updateUserAction({cards: updateCardArray, server_time: response.server_time}));
        }

        // const {data} = await getReferralQuery({user_id: user.id});
        // const newReferralCoins = (data?.total_earn ?? referralTotalEarn) - referralTotalEarn;
        dispatch(updateBackgroundMining());

        const newReferralCoins = (data?.total_earn ?? referralTotalEarn) - referralTotalEarn;

        if (server_time && lastCoinUpdate && backgroundMining) {
          const backgroundCoinsNew = calculateNewCoinsFromBackground(server_time, lastCoinUpdate, backgroundMining);

          if (backgroundCoinsNew && backgroundCoinsNew >= 1) {
            dispatch(setNewCoins(backgroundCoinsNew));
            await addIncome({coins_num: parseInt(backgroundCoinsNew.toFixed(0), 10), id: response.id});
          }
        }
        if (data) {
          dispatch(setReferralDataAction(data));
        }

        dispatch(setIsLoadingAction(false));
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (isMobile) {
        dispatch(setIsLoadingAction(true));
        WebApp.expand();
        getInitialData();
      }
    }, []);
};

export default useGetInitialData;
