import {CSSProperties} from 'react';
import {colors} from '../../constants';
import {Styles} from '../../types';

export const styles: Styles = {
  container: {
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.gray_272A2F,
    background: 'linear-gradient(0deg, #2C283E 50%, #364147 100%)',
    borderRadius: '16px',
  },
  imgView: {
    borderRadius: '8px',
    width: '32px',
    height: '32px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(180deg, #FFFFFF 0%, #D5D4EE 100%)',
  },
  infoView: {
    width: 'calc(100% - 16px)',
    marginLeft: '8px',
    justifyContent: 'center',
    borderRadius: '10px',
    paddingBottom: '20px',
    paddingTop: '5px',
    alignItems: 'center',
    background: 'linear-gradient(0deg, #141414 10%, #1A3249 30%, #141414 100%)',
  },
  title: {
    color: colors.white,
    fontSize: '12px',
    lineHeight: '22px',
    fontWeight: '700',
  },
  level: {
    fontSize: '10px',
    lineHeight: '22px',
    color: colors.gray_AFAFAF,
    marginBottom: '10px',
  },
  profitPerHour: {
    fontSize: '10px',
    lineHeight: '22px',
    color: colors.gray_AFAFAF,
    letterSpacing: '-0.41px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  profitPerHourInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
  },
  profitPerHourPrice: {
    fontSize: '12px',
    lineHeight: '22px',
    color: colors.white,
    fontWeight: '700',
    letterSpacing: '-0.41px',
  },
  improveLevelView: {
    backgroundColor: colors.black_1D1D1D,
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingRight: '10px',
    alignItems: 'center',
    paddingTop: '5px',
    paddingBottom: '6px',
    width: 'calc(100% - 36px)',
    marginLeft: '8px',
  },
  disable: {
    backgroundColor: colors.gray_545454,
  },
  improveLevelTitle: {
    color: colors.white,
    fontSize: '10px',
    lineHeight: '22px',
  },
  improveLevelPriceContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  improveLevelPrice: {
    fontSize: '16px',
    color: colors.white,
    fontWeight: '700',
    letterSpacing: '-0.41px',
  },
};

export const iconStyle: CSSProperties = {
  width: '20px',
  height: '20px',
};
