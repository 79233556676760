import {Box, Typography} from '@mui/material';
import {Container, styles} from './styles';
import {ReactComponent as FriendsSVG} from '../../../assets/icons/tabs/friends.svg';
import {ReactComponent as LinesSVG} from '../../../assets/icons/tabs/lines.svg';
import {useMemo} from 'react';
import {Styles} from '../../../types';
import {ReactComponent as ChevronSVG} from '../../../assets/icons/tabs/chevron.svg';
import {ReactComponent as SparksSVG} from '../../../assets/icons/tabs/sparks.svg';

type TProps = {
  title: string;
  isActive?: boolean;
};

const getIcon = (title: string) => {
  switch (title) {
    case 'Reward':
      return <SparksSVG />;
    case 'Boost':
      return <ChevronSVG />;
    case 'Friends':
      return <FriendsSVG />;
    case 'Other':
      return <LinesSVG />;
    default:
      return <SparksSVG />;
  }
};

const NavigationButton = ({title, isActive = false}: TProps) => {
  const iconSrc = useMemo(() => getIcon(title), [title]);

  return (
    <Container $isActive={isActive}>
      {iconSrc}
      <Typography sx={{...styles.title, ...(isActive && styles.isActive)} as Styles}>{title}</Typography>
    </Container>
  );
};

export default NavigationButton;
