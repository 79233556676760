import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TGlobalState } from '../types';

type TInitialState = TGlobalState['levels'];

export const levelsInitialState: TInitialState = {
  data: [],
};

const levelsSlice = createSlice({
  name: '@@levels',
  initialState: levelsInitialState,
  reducers: {
    setLevelsDataAction: (state, action: PayloadAction<TInitialState>) => {
      state.data = action.payload.data;
    },

    resetLevelsAction: () => levelsInitialState,
  },
});

// actions
export const { setLevelsDataAction, resetLevelsAction } = levelsSlice.actions;

// reducer
export const levels = levelsSlice.reducer;

// selectors
export const selectLevels = (state: TGlobalState) => state.levels.data;
