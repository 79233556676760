import React from 'react';
import WebApp from '@twa-dev/sdk';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './store';
import {BrowserRouter as Router, Route, Routes, Link, useLocation} from 'react-router-dom';
import {GlobalStyles} from '@mui/material';
import {colors} from './constants';
import {ErrorBoundary} from './components';
declare global {
  interface Window {
    TelegramGameProxy: any;
  }
}

WebApp.ready();
WebApp.enableClosingConfirmation();
WebApp.disableVerticalSwipes();
WebApp.setHeaderColor('#000');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Router>
    <GlobalStyles
      styles={{
        body: {backgroundColor: colors.background},
      }}
    />
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </Router>,
);

reportWebVitals();
