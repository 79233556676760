import styled from 'styled-components';
import {colors} from '../../../constants';

export const Container = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  bottom: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.red_B30D02};
  border-radius: 40px;
`;
