// api.ts
import {Mutex} from 'async-mutex';
import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import {Action, Middleware} from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_FOR_ASSETS = process.env.REACT_APP_BASE_URL;

type RootState = any // normally inferred from state

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, {getState}) => {
    headers.set('Content-Type', 'application/json');
    return headers;
  },
});

const mutex = new Mutex();

const baseQueryWithRefreshToken: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  return result;
};

function isHydrateAction(action: Action): action is Action<typeof REHYDRATE> & {
  key: string
  payload: RootState
  err: unknown
} {
  return action.type === REHYDRATE
}

export const splitApi = createApi({
  reducerPath: '@@splitApi',
  baseQuery: baseQueryWithRefreshToken,
  // extractRehydrationInfo(action): any {
  //   if (isHydrateAction(action)) {
  //     if (action.key === 'root') {
  //       console.log('action.payload ---- ', action.payload);
  //       return action.payload
  //     }

  //     return action.payload[splitApi.reducerPath]
  //   }
  // },
  tagTypes: [''],
  endpoints: () => ({}),
});

export const splitApiReducer = splitApi.reducer;

export const splitApiMiddleware: Middleware[] = [splitApi.middleware];
