import {urls} from '../../../constants';
import {TFightData, TFightUnitData} from '../../../types/entities/fight';
import {splitApi} from '../api';
import {IToggleUserFightStatusResponce, TGetFightPayload, TGetFightResponse} from './types';

export const fightlApi = splitApi.injectEndpoints({
  endpoints: build => ({
    getUserFightsData: build.query<TGetFightResponse, TGetFightPayload>({
      query: params => ({
        method: 'GET',
        url: `${urls.getFightsUserData}/${params.user_id}`,
      }),
    }),
    toggleUserFightStatus: build.mutation<IToggleUserFightStatusResponce, TGetFightPayload>({
      query: params => ({
        method: 'PUT',
        url: `${urls.getFightsUserData}/${params.user_id}/toggle-status`,
      }),
    }),
    setUserFightScore: build.mutation<
      any,
      {
        body: {
          score: number;
        };
        id: string;
        userId: string;
        score: number;
      }
    >({
      query: params => ({
        method: 'PUT',
        url: `/api/v1/fights/${params.id}/${params.userId}`,
        body: params.body,
      }),
    }),
    getFight: build.query<TFightData, {id: string}>({
      query: params => ({
        method: 'GET',
        url: `/api/v1/fights/${params.id}`,
      }),
    }),
  }),
});
