import {Box, Typography} from '@mui/material';
import {Button, styles} from './styles';
import {Styles} from '../../../types';
import FightSVG from '../../../assets/icons/fight.svg';
import {ReactSVG} from 'react-svg';
import {assets} from '../../../assets';

type TProps = {
  title?: string;
  containerStyle?: any;
  onClick: () => void;
};

const FightWithFriendsButton = ({title, onClick, containerStyle}: TProps) => {
  return (
    <Button onClick={onClick}>
      <Box sx={{display: 'flex', alignItems: 'center', marginLeft: '4px'}}>
        <ReactSVG
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 24px; height: 24px; display: block; margin: auto;');
          }}
          src={FightSVG}
        />
        {title ? <Typography sx={styles.title}>{title}</Typography> : null}
      </Box>

      <img
        src={assets.dinoGroup}
        style={{
          height: '160%',
          right: '0px',
          bottom: '0px',
          position: 'absolute',
        }}
        alt="FightFriendsPNG"
      />
    </Button>
  );
};

export default FightWithFriendsButton;
