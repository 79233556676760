import { TLevels } from "../types/entities";

export function generateLevelThresholds(levels: TLevels[]): {[key: number]: number} {
  return levels.reduce((acc, level) => {
      acc[level.level] = level.coins_count;
      return acc;
  }, {} as {[key: number]: number});
}

export const getProgress = (currentLevel: number, monets: number, levels: TLevels[]): number => {
  const levelThresholds = generateLevelThresholds(levels);
  const maxValue = levelThresholds[currentLevel >= 10 ? currentLevel : currentLevel + 1];
  const progress = (monets / maxValue) * 100;

  if (progress > 100) {
    return 100;
  } else if (progress < 0) {
    return 0;
  }
  return parseFloat(progress.toFixed(2));
};

export const getCurrentLvlProgress = (currentLevel: number, monets: number): any => {
  // if (currentLevel >= 10) {
  //   return 100;
  // }

  // const currentLevelThreshold = levelThresholds[currentLevel];
  // const nextLevelThreshold = levelThresholds[currentLevel + 1];

  // const coinsForCurrentLevel = monets - currentLevelThreshold;
  // const coinsNeededForNextLevel = nextLevelThreshold - currentLevelThreshold;

  // return parseFloat(((coinsForCurrentLevel / coinsNeededForNextLevel) * 100).toFixed(2));
};

export const getLevelFromCoins = (coins: number): any => {
  // let level = 0;
  // for (const [key, value] of Object.entries(levelThresholds)) {
  //   if (coins >= value) {
  //     level = parseInt(key);
  //   } else {
  //     break;
  //   }
  // }

  // return level as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
};
