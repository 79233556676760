import { urls } from '../../../constants';
import { splitApi } from '../api';
import { IUpdateUserCardResponse, TUpdateUserCardPayload } from './types';

export const boostApi = splitApi.injectEndpoints({
    endpoints: (build) => ({
        updateUserCard: build.mutation<IUpdateUserCardResponse, TUpdateUserCardPayload>({
            query: ({ user_id, card_id, data }) => ({
                method: 'PUT',
                url: `api/v1/${user_id}/cards/${card_id}`,
                body: data,
            }),
        }),
    }),
});

export const { useUpdateUserCardMutation } = boostApi;
