import {Box, Typography} from '@mui/material';
import {ReactSVG} from 'react-svg';
import {styles} from './styles';
import CoinSVG from '../../../../assets/icons/coin.svg';
import SparksSVG from '../../../../assets/icons/tabs/sparks.svg';
import {formatNumberWithSpace} from '../../helpers';

type TProps = {
  coins: number;
  title: string;
};

const RowItem = ({coins, title}: TProps) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.iconView}>
        <ReactSVG
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 24px; height: 24px; display: block; margin: auto;');
          }}
          src={SparksSVG}
        />
      </Box>
      <Box sx={styles.infoView}>
        <Typography sx={styles.title}>{title}</Typography>
        <Box sx={styles.coinsView}>
          <ReactSVG
            src={CoinSVG}
            beforeInjection={svg => {
              svg.setAttribute('style', 'width: 16px; height: 16px; display: block;');
            }}
          />
          <Typography sx={styles.coins}>+{formatNumberWithSpace(coins)}</Typography>
          <Typography sx={styles.coinDesc}>{'\u00A0'}for you and your friend</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RowItem;
