import React from 'react';
import {Typography} from '@mui/material';
import {AmountContainer, Container, styles} from './styles';
import {ReactSVG} from 'react-svg';
import CoinSVG from '../../../assets/icons/coinMax.svg';
import {formattedNumber} from '../../../helpers';

type TProps = {
  coins: number;
};

const TotalBalance = ({coins}: TProps) => {
  return (
    <Container>
      <Typography sx={styles.title}>Total Balance</Typography>
      <AmountContainer>
        <ReactSVG src={CoinSVG} />
        <Typography sx={styles.value}>{formattedNumber(Number(coins.toFixed(0)))}</Typography>
      </AmountContainer>
    </Container>
  );
};

export default TotalBalance;
