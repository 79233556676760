import React from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import {formattedNumber} from '../../../helpers';
import {assets} from '../../../assets';

type TProps = {
  coins: number;
};

const CoinTotalTracker = ({coins}: TProps) => {
  return (
    <Box sx={styles.container}>
      <img
        src={assets.coin}
        alt="coin"
        style={{
          width: '28px',
          height: '28px',
        }}
      />
      <Typography sx={styles.value}>{formattedNumber(Math.floor(coins))}</Typography>
    </Box>
  );
};

export default CoinTotalTracker;
