import {motion} from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
`;
export const Header = styled.div`
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
`;

export const GameContainer = styled.div`
  display: block;
  height: calc(100% - 83px);
  position: relative;
`;

export const FloatingNumber = styled(motion.div)`
  font-size: 24px;
  color: yellow;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  z-index: 1000;
`;
