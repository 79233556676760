import styled from 'styled-components';
import {colors} from '../../../constants';
import {Styles} from '../../../types';

export const styles: Styles = {
  container: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
    alignItems: 'center',
    borderRadius: '8px',
  },
  disable: {
    backgroundColor: colors.gray_545454,
  },
  title: {
    textDecoration: 'none',
    color: colors.white,
    fontSize: '14px',
    fontWeight: '700',
    marginLeft: '10px',
    textTransform: 'none',
  },
};

export const Button = styled.button<{$background?: string; $disable?: boolean}>`
  width: 100%;
  flex: 1;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  border-radius: 8px;
  background: ${({$background, $disable}) => ($disable ? colors.gray_545454 : $background || colors.red_B30D02)};
  display: flex;
  /* z-index: 999999; */
  border: none;
  outline: none;
  box-shadow: none;
`;

export const ButtonTitle = styled.span<{$color?: string}>`
  text-decoration: none;
  color: ${({$color}) => $color || colors.white};
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  text-transform: none;
`;
