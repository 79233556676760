import {CSSProperties} from 'react';
import {colors} from '../../constants';
import {Styles} from '../../types';

export const styles: Styles = {
  container: {
    width: '100%',
    paddingTop: '6px',
    paddingBottom: '12px',
    textAlign: 'center',
    marginLeft: '4px',
    marginRight: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.gray_272A2F,
    borderRadius: '16px',
  },
  title: {
    color: colors.white,
    fontSize: '10px',
    marginBottom: '7px',
  },
  valueContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  value: {
    lineHeight: '22px',
    letterSpacing: '-0.41px',
    fontSize: '20px',
    fontWeight: '700',
    marginLeft: '2px',
    color: colors.white,
  },
};

export const iconStyle: CSSProperties = {
  width: '22px',
  height: '23px',
};
