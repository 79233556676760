import React, {useCallback, useEffect, useState} from 'react';
import {useAppSelector, useGetReferralQuery, useLazyGetReferralQuery} from '../../hooks';
import {selectUser} from '../../reducers/user';
import {Box, Button, Typography} from '@mui/material';
import {styles} from './styles';
import RowItem from './components/RowItem/RowItem';
import FriendsSVG from '../../assets/icons/tabs/friends.svg';
import {ReactSVG} from 'react-svg';
import CoinSVG from '../../assets/icons/coin.svg';
import CopySVG from '../../assets/icons/copy.svg';
import {Loader} from '../../components';
import {selectReferral} from '../../reducers/referral';
import {useLocation} from 'react-router-dom';

const Friends = () => {
  const {data: user} = useAppSelector(selectUser);
  const {referrals, total_earn} = useAppSelector(selectReferral);
  // const [getReferralQuery, {isLoading, data: userRefferal}] = useLazyGetReferralQuery();
  const {data, isLoading} = useGetReferralQuery({user_id: user?.id ?? ''}, {skip: !user?.id});
  // const [referralUrl, setReferralUrl] = useState<string>('');
  // const [referralCode, setReferralCode] = useState<string>('');

  if (!user) {
    return <></>;
  }

  // useEffect(() => {
  //   // getData();
  // }, []);

  // const getData = async () => {
  //   try {
  //     const {data} = await getReferralQuery({user_id: user.id});
  //     if (data?.referral_code) setReferralCode(data?.referral_code);
  //     if (data?.referral_url) setReferralUrl(data?.referral_url);
  //   } catch (error) {}
  // };

  const handleShare = useCallback(() => {
    if (data) {
      const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
        data.referral_url,
      )}&text=${encodeURIComponent('Hey, join me in Rexy! Tap to earn coins and become the king of the dinos!')}`;
      window.open(telegramUrl, '_blank');
    }
  }, [data?.referral_url]);

  const handleCopy = useCallback(() => {
    if (data) {
      navigator.clipboard
        .writeText(data?.referral_url)
        .then(() => {})
        .catch(err => {});
    }
  }, [data?.referral_url]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={styles.container}>
          <Box>
            <Box sx={styles.headStyle}>
              <Typography sx={styles.title}>Friends</Typography>
              <Typography sx={styles.description}>
                Invite friends and mine together! Players with the biggest friend network usually have the highest
                bonuses
              </Typography>
            </Box>
            <RowItem coins={50000} title={'Invite friend'} />
            <Box sx={styles.referralStatusView}>
              <Typography sx={styles.referralStatusTitle}>Your referral status</Typography>
              <Box sx={styles.referralInfoView}>
                <Typography sx={styles.referralInfoTitle}>Invite people</Typography>
                <Box sx={styles.referralInfoRightView}>
                  <ReactSVG style={{marginRight: '4px'}} src={FriendsSVG} className="icon-svg" />
                  <Typography sx={styles.count}>{referrals}</Typography>
                </Box>
              </Box>
              <Box sx={styles.referralInfoView}>
                <Typography sx={styles.referralInfoTitle}>Earned by your referrals</Typography>
                <Box sx={styles.referralInfoRightView}>
                  <ReactSVG
                    src={CoinSVG}
                    beforeInjection={svg => {
                      svg.setAttribute('style', 'width: 16px; height: 16px; display: block; margin: auto;');
                    }}
                  />
                  <Typography sx={styles.count}>{total_earn}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.actionView}>
            <Button onClick={handleShare} sx={styles.invite}>
              <Typography sx={styles.buttonText}>Invite now</Typography>
            </Button>
            <Button onClick={handleCopy} sx={styles.copy}>
              <ReactSVG style={{lineHeight: '0px'}} src={CopySVG} />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Friends;
