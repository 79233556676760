import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '.';
import { selectUser, setCoinsAction } from '../reducers/user';


const useBackgroundMining = () => {
  const dispatch = useAppDispatch();
  const { backgroundMining } = useAppSelector(selectUser);
  const intervalRef = useRef<any>(null);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (backgroundMining !== 0) {
      intervalRef.current = setInterval(() => {
        dispatch(setCoinsAction(backgroundMining));
      }, 1000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [backgroundMining, dispatch]);
}

export default useBackgroundMining;
