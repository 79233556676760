import {urls} from '../../../constants';
import {splitApi} from '../api';
import {TGetReferralPayload, TGetReferralResponse} from './types';

export const addIncomeApi = splitApi.injectEndpoints({
  endpoints: build => ({
    addIncome: build.mutation<TGetReferralResponse, TGetReferralResponse>({
      query: body => ({
        method: 'PUT',
        body,
        url: `api/v1/user/${body.id}/add-income`
      })
    })
  })
});
