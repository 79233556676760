import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Container, Description, FightButtonContainer, FightStatusContainer, FightStatusTitle, Title} from './styles';
import {BackgroundImgContainer, Loader, UsuallyButton} from '../../components';
import {useNavigate} from 'react-router-dom';
import {assets} from '../../assets';
import TotalAmount from './components/TotalAmount/TotalAmount';
import {EStatusType} from './types';
import {useAppSelector, useFormattedFightData, useGetFightQuery, useGetUserFightsDataQuery} from '../../hooks';
import {selectUser} from '../../reducers/user';
import {colors} from '../../constants';
import {selectFight} from '../../reducers/fight';

type TProps = {};

const ComingSoon = ({}: TProps) => {
  const navigate = useNavigate();
  const {data: user} = useAppSelector(selectUser);
  const {lastFightId} = useAppSelector(selectFight);

  const {data: activeFightData, refetch} = useGetFightQuery({id: lastFightId ?? ''}, {skip: !lastFightId});

  const {data, isLoading} = useGetUserFightsDataQuery({user_id: user?.id ?? ''}, {skip: !user?.id});

  const formattedFightData = useFormattedFightData(activeFightData ?? data?.fight, user?.id ?? null);

  const onNavigateToSearchOpponents = useCallback(() => {
    if (!!lastFightId && formattedFightData?.host.score !== null) {
      refetch();
      navigate('/fight-result');
    } else {
      navigate('/search-opponents');
    }
  }, [navigate, lastFightId, formattedFightData]);

  return (
    <BackgroundImgContainer $url={assets.figthMain}>
      <Container>
        <Title>Fight with friends</Title>
        <Description>
          Invite friends and mine together! Players with the biggest friend network usually have the highest bonuses
        </Description>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FightStatusContainer>
              <FightStatusTitle>Your status</FightStatusTitle>
              <TotalAmount title="Your fights" amount={data?.fights ?? 0} svgType={EStatusType.FIGHT} />
              <TotalAmount title="Earned by fights" amount={data?.coins_num ?? 0} svgType={EStatusType.COINS} />
            </FightStatusContainer>
            <FightButtonContainer>
              <UsuallyButton
                title="Fight!"
                onClick={onNavigateToSearchOpponents}
                background={`linear-gradient(90deg, ${colors.main_gradient_first_color_A92E2A} 0%, ${colors.main_gradient_second_color_4C1881} 100%)`}
              />
            </FightButtonContainer>
          </>
        )}
      </Container>
    </BackgroundImgContainer>
  );
};

export default ComingSoon;
