import {colors} from '../../constants';
import {Styles} from '../../types';

export const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.background,
    paddingLeft: '16px',
    paddingRight: '16px',
    height: '100vh',
    justifyContent: 'space-between',
  },
  headStyle: {},
  title: {
    marginTop: '24px',
    fontSize: '20px',
    fontWeight: '700',
    color: 'white',
    textAlign: 'center',
    letterSpacing: '-0.41px',
    marginBottom: '8px',
  },
  description: {
    fontSize: '12px',
    color: '#A4A4A4',
    textAlign: 'center',
    marginBottom: '32px',
  },
  referralStatusView: {
    display: 'contents',
    width: '100%',
  },
  referralStatusTitle: {
    color: colors.white,
    fontWeight: '700',
    display: 'inline-grid',
    letterSpacing: '-0.41px',
    marginBottom: '8px',
  },
  referralInfoView: {
    display: 'flex',
    paddingTop: '8px',
    marginBottom: '8px',
    paddingBottom: '8px',
    borderRadius: '16px',
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
    minHeight: '26px',
    background: 'linear-gradient(90deg, #2C283E 0%, #364147 100%)',
  },
  referralInfoTitle: {
    fontSize: '12px',
    fontWeight: '700',
    color: colors.white,
    flex: 1,
    letterSpacing: '-0.41px',
  },
  count: {
    color: colors.white,
    fontSize: '12px',
    fontWeight: '700',
    letterSpacing: '-0.41px',
  },
  referralInfoRightView: {
    display: 'flex',
    alignItems: 'center',
  },
  actionView: {
    display: 'flex',
    width: '100%',
    marginBottom: '112px',
  },
  invite: {
    borderRadius: '8px',
    flex: 1,
    height: '43px',
    background: `linear-gradient(90deg, ${colors.main_gradient_first_color_A92E2A} 0%, ${colors.main_gradient_second_color_4C1881} 100%)`,
  },
  copy: {
    borderRadius: '8px',
    width: '71px',
    height: '43px',
    marginLeft: '8px',
    background: `linear-gradient(90deg, ${colors.main_gradient_first_color_A92E2A} 0%, ${colors.main_gradient_second_color_4C1881} 100%)`,
  },
  buttonText: {
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'initial',
    fontSize: '16px',
    fontWeight: '700',
    letterSpacing: '-0.41px',
  },
};
