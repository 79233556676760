export const currentTimestampInSeconds = () => Math.floor(Date.now() / 1000);
export const calculateNewCoinsFromBackground = (
  serverTime: string,
  lastCoinUpdate: number,
  backgroundMining: number,
) => {
  const date1 = new Date(serverTime);
  const date2 = new Date(lastCoinUpdate * 1000);
  const differenceInMillis = Math.abs(date1.getTime() - date2.getTime());
  const differenceInSeconds = Math.floor(differenceInMillis / 1000);

  if (differenceInSeconds < 10800) {
    const newCoins = differenceInSeconds * backgroundMining;

    return newCoins;
  } else {
    return 10800 * backgroundMining;
  }
};
