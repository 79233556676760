import { urls } from '../../../constants';
import { splitApi } from '../api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface Level {
    id: string;
    name: string;
    coins_count: number;
    coins_per_click: number;
    level: number;
}

export type TGetLevelsResponse = Level[];

export interface TUpdateLevelRequest {
    levelId: string;
    userId: string;
}

export const levelsApi = splitApi.injectEndpoints({
    endpoints: (build) => ({
        getLevels: build.query<TGetLevelsResponse, void>({
            query: () => ({
                method: 'GET',
                url: `${urls.getLevels}`,
            }),
        }),
        updateLevel: build.mutation<void, TUpdateLevelRequest>({
            query: ({ userId, levelId }) => ({
                method: 'PUT',
                url: `api/v1/${userId}/levels/${levelId}`
            }),
        }),
    }),
});

export const { useGetLevelsQuery, useUpdateLevelMutation } = levelsApi;
