import React from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import bigEllipseImage from '../../../../../assets/images/bigEllipse.png';
import smallEllipseImage from '../../../../../assets/images/smallEllipse.png';
import {Styles} from '../../../../../types';
import {assets} from '../../../../../assets';

type TProps = {};

const OneScreen = ({}: TProps) => {
  return (
    <Box sx={{...styles.container}}>
      <img
        src={bigEllipseImage}
        alt="Image 1"
        style={{
          width: '100%',
        }}
      />
      <img
        src={smallEllipseImage}
        alt="Image 2"
        style={{
          width: '70%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
      <img
        src={assets.dinosaur}
        alt="coin"
        style={{
          width: '73%',
          position: 'absolute',
          top: '55%',
          left: '40%',
          transform: 'translate(-50%, -50%)',
        }}
      />
      <Typography sx={{...styles.t, ...styles.t1} as Styles}>+1</Typography>
      <Typography sx={{...styles.t, ...styles.t2} as Styles}>+1</Typography>
      <Typography sx={{...styles.t, ...styles.t3} as Styles}>+1</Typography>
      <Typography sx={{...styles.t, ...styles.t4} as Styles}>+1</Typography>
      <Typography sx={{...styles.t, ...styles.t5} as Styles}>+1</Typography>
      <Typography sx={{...styles.t, ...styles.t6} as Styles}>+1</Typography>
      <Typography sx={{...styles.t, ...styles.t7} as Styles}>+1</Typography>
    </Box>
  );
};

export default OneScreen;
