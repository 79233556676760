import {colors} from '../../constants';
import {Styles} from '../../types';

export const styles: Styles = {
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '24px',
    backgroundColor: colors.background,
    height: '100vh',
  },
};
