import {assets} from '../../assets';

export const eggsData = [
  {
    src: assets.smallEgg,
    height: '40px',
    width: '40px',
    coast: 5,
  },
  {
    src: assets.mediumEgg,
    height: '60px',
    width: '48px',
    coast: 4,
  },
  {
    src: assets.largeEgg,
    height: '70px',
    width: '58px',
    coast: 3,
  },
  {
    src: assets.extraLargeEgg,
    height: '80px',
    width: '68px',
    coast: 2,
  },
];
