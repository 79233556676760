import {motion} from 'framer-motion';
import styled from 'styled-components';

export const FloatingNumber = styled(motion.div)`
  font-size: 24px;
  color: yellow;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  z-index: 1000;
`;
