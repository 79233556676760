import {Typography} from '@mui/material';
import {Button, ButtonTitle, styles} from './styles';
import {ReactSVG} from 'react-svg';
import NextSVG from '../../../assets/icons/next.svg';
import PencilSVG from '../../../assets/icons/pencil.svg';
import {colors} from '../../../constants';

type TProps = {
  title?: string;
  $color?: string;
  leftIcon?: string;
  rightIcon?: string;
  disable?: boolean;
  background?: string;
  containerStyle?: any;
  onClick: () => void;
};

const getIcon = (title: string) => {
  switch (title) {
    case 'next':
      return NextSVG;
    case 'pencil':
      return PencilSVG;
    default:
      return NextSVG;
  }
};

const UsuallyButton = ({
  title,
  rightIcon,
  leftIcon,
  background = colors.red_B30D02,
  disable = false,
  onClick,
  containerStyle,
  $color,
}: TProps) => {
  return (
    <Button $disable={disable} $background={background} disabled={disable} onClick={onClick} style={containerStyle}>
      {leftIcon ? (
        <ReactSVG
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 20px; height: 20px; display: block; margin: auto;');
          }}
          src={getIcon(leftIcon)}
        />
      ) : null}
      {title ? <ButtonTitle $color={$color}>{title}</ButtonTitle> : null}
      {rightIcon ? (
        <ReactSVG
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 24px; height: 24px; display: block; margin: auto;');
          }}
          src={getIcon(rightIcon)}
        />
      ) : null}
    </Button>
  );
};

export default UsuallyButton;
