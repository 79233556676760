import React from 'react';
import {Box} from '@mui/material';
import {styles} from './styles';
import Onboarding2Image from '../../../../../assets/images/onboarding2.png';

type TProps = {};

const TwoScreen = ({}: TProps) => {
  return (
    <Box sx={styles.container}>
      <img
        src={Onboarding2Image}
        alt="Image 1"
        style={{
          width: '100%',
          display: 'block',
        }}
      />
    </Box>
  );
};

export default TwoScreen;
