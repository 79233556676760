import React from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import {ReactSVG} from 'react-svg';
import {UsuallyButton} from '../..';
import CoinSVG from '../../../assets/icons/coin.svg';
import {TReward} from '../../../types/entities';

type TProps = {
  reward: TReward;
  onApprove: () => void;
};

const CoinsDailyReward = ({reward, onApprove}: TProps) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.profitPerHourInfo}>
        <Box sx={styles.iconContainer}>
          <ReactSVG
            src={CoinSVG}
            beforeInjection={svg => {
              svg.setAttribute('style', 'width: 44; height: 44; display: block; margin: auto;');
            }}
          />
        </Box>
        <Typography sx={styles.profitPerHourPrice}>{reward.coins_number.toFixed(0)}</Typography>
      </Box>
      <Typography sx={styles.title}>You have received your daily reward!</Typography>
      <UsuallyButton onClick={onApprove} title="Thank you!" />
    </Box>
  );
};

export default CoinsDailyReward;
