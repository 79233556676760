import {Styles} from '../../../types';
import {colors} from '../../../constants';

export const styles: Styles = {
  container: {
    width: '100%',
    paddingTop: '32px',
  },
  title: {
    color: colors.white,
    fontSize: '12px',
    letterSpacing: '-0.41px',
    marginBottom: '16px',
  },
  button: {
    opacity: '0.5',
  },
};
