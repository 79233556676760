import {urls} from '../../../constants';
import {splitApi} from '../api';
import {TGetRoadMapPayload, TGetRoadMapResponse} from './types';

export const roadMapApi = splitApi.injectEndpoints({
  endpoints: build => ({
    getRoadMap: build.query<TGetRoadMapResponse, TGetRoadMapPayload>({
      query: () => ({
        method: 'GET',
        url: `${urls.getRoadMap}`,
      }),
    }),
  }),
});
