import React, {useCallback, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import CardRow from '../../CardRow/CardRow';
import {SkillUpgrade, UsuallyModal} from '../..';
import {useAppDispatch, useAppSelector, useUpdateUserCardMutation} from '../../../hooks';
import {selectUser, setInitialUserData, upgradeMainer} from '../../../reducers/user';
import {TMainer} from '../../../types/entities';

const CardsContainer = () => {
  const { data: user, initialUserData } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [updateUserCards] = useUpdateUserCardMutation();

  const [selectedMainer, setSelectedMainer] = useState<{
    isVisibleModal: boolean;
    mainer?: TMainer;
  }>({
    isVisibleModal: false,
    mainer: undefined,
  });

  const openSelectMainer = useCallback((item: TMainer) => {
    setSelectedMainer({
      isVisibleModal: true,
      mainer: item,
    });
  }, []);
  const handleClose = useCallback(() => {
    setSelectedMainer({...selectedMainer, isVisibleModal: false});
  }, [selectedMainer]);

  const handleApprove = useCallback(async (mainer: TMainer) => {
    try {
      const newMainer = { ...mainer };
      newMainer.level += 1;
      newMainer.earnings_h = mainer.level === 0 ? newMainer.earnings_h : mainer.earnings_h + newMainer.earnings_h;
      newMainer.price = mainer.level === 0 ? newMainer.price : mainer.price * 3;

      if (user && initialUserData) {
        const coins_income = user.coins_num - initialUserData.coins_num;
        const {
            id,
            name,
            level,
            price,
            image,
            earnings_h,
            description,
            user: newUser,
        } = await updateUserCards({
          user_id: user.id,
          card_id: newMainer.id,
          data: {
            coins_income: parseInt(coins_income.toFixed(0), 10),
            earnings_h: newMainer.earnings_h,
            price: newMainer.price,
            level: newMainer.level
          }
        }).unwrap();

        if (newUser?.coins_num) dispatch(setInitialUserData({ coins_num: newUser.coins_num }));
  
        dispatch(upgradeMainer({ newMainer: {
          id,
          name,
          level,
          price,
          image,
          earnings_h,
          description
        }, price: mainer.level === 0 ? newMainer.price : mainer.price * 3 }));
      }
      handleClose();
    } catch(error) {
      console.error(error);
      handleClose();
    }
    },
    [handleClose, dispatch],
  );

  return (
    <Box sx={styles.container}>
      {user?.cards.map(el => (
        <CardRow coins={user.coins_num} onClick={openSelectMainer} key={el.name} item={el} />
      ))}
      <Typography></Typography>
      <UsuallyModal isVisible={selectedMainer.isVisibleModal} handleClose={handleClose}>
        {selectedMainer.mainer && (
          <SkillUpgrade coins={user?.coins_num || 0} onApprove={handleApprove} mainer={selectedMainer.mainer} />
        )}
      </UsuallyModal>
    </Box>
  );
};

export default CardsContainer;
