import styled from 'styled-components';
import {colors} from '../../constants';
import {Styles} from '../../types';

export const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.background,
    height: '100vh',
  },
  title: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '-0.41px',
    marginTop: '24px',
  },
  gridContainer: {
    display: 'grid',
    width: 'calc(100% - 32px)',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(0, 0)',
    gap: '8px',
  },
  cell: {
    position: 'relative',
    width: '100%',
    borderRadius: '8px',
    paddingBottom: '100%',
    background: '#2E2E2E',
  },

  disabledCell: {
    background: 'linear-gradient(90deg, #2C283E 0%, #364147 100%)',
  },
  activeCell: {
    background: `linear-gradient(90deg, ${colors.main_gradient_first_color_A92E2A} 0%, ${colors.main_gradient_second_color_4C1881} 100%)`,
  },

  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  coinsView: {
    display: 'flex',
    alignItems: 'center',
  },
  number: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '-0.41px',
  },
};

export const HeaderContainer = styled.div`
  margin-top: 16px;
  padding-inline: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 32px;
`;
