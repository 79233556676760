import React, {useEffect, useState} from 'react';
import {Container} from './styles';

type TProps = {
  $width: string;
  $height: string;
  src: string;
  onPress: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

type TCoordinates = {
  x: number;
  y: number;
};

const extractNumberFromPixelString = (pixelString: string): number => {
  const match = pixelString.match(/^(\d+)/);
  return match ? parseInt(match[1], 10) : 0;
};

const getRandomCoordinatesOutsideContainer = (containerWidth: number, containerHeight: number): TCoordinates => {
  const side = Math.floor(Math.random() * 4);
  let x = 0;
  let y = 0;

  switch (side) {
    case 0:
      x = -Math.random() * containerWidth;
      y = Math.random() * containerHeight;
      break;
    case 1:
      x = containerWidth + Math.random() * containerWidth;
      y = Math.random() * containerHeight;
      break;
    case 2:
      x = Math.random() * containerWidth;
      y = -Math.random() * containerHeight;
      break;
    case 3:
      x = Math.random() * containerWidth;
      y = containerHeight + Math.random() * containerHeight;
      break;
  }

  return {x, y};
};

const getRandomCoordinatesInsideContainer = (
  containerWidth: number,
  containerHeight: number,
  width: number,
  height: number,
): TCoordinates => {
  const x = Math.random() * containerWidth;
  const y = Math.random() * containerHeight;

  const xCoordinate = x + width > containerWidth ? x - width : x;
  const yCoordinate = y + height > containerHeight ? y - height : y;

  return {x: xCoordinate, y: yCoordinate};
};

const EggItem: React.FC<TProps> = ({$height, $width, src, onPress}) => {
  const [isChange, setIsChange] = useState(true);
  const [startXY, setStartXY] = useState<null | TCoordinates>(null);
  const [endXY, setEndXY] = useState<null | TCoordinates>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [key, setKey] = useState(0);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isChange) {
      const end = getRandomCoordinatesInsideContainer(
        window.innerWidth,
        window.innerHeight - 200,
        extractNumberFromPixelString($width),
        extractNumberFromPixelString($height),
      );
      const start = getRandomCoordinatesOutsideContainer(window.innerWidth, window.innerHeight - 250);
      const endRotate = Math.random() * 360;
      setRotate(endRotate);
      setStartXY(start);
      setEndXY(end);
      setIsChange(false);
      setKey(prevKey => prevKey + 1);
    }
  }, [isChange, $width, $height]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isClicked) {
      setIsClicked(true);
      onPress(event);
      setScale(0);
      setTimeout(() => {
        setIsChange(true);
        setScale(1);
        setIsClicked(false);
      }, 200);
    }
  };

  return (
    <>
      {startXY && endXY ? (
        <Container
          onClick={handleClick}
          $height={$height}
          key={key}
          $width={$width}
          startXY={endXY}
          transition={{duration: 1, ease: 'backOut'}}
          animate={{x: endXY.x, y: endXY.y, scale, rotate}}
          initial={{x: startXY.x, y: startXY.y}}>
          <img src={src} style={{flex: 1}} alt={src} />
        </Container>
      ) : null}
    </>
  );
};

export default EggItem;
