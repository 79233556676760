import React from 'react';
import {Box} from '@mui/material';
import {styles} from './styles';
import {Styles} from '../../../../../types';
import {UsuallyButton} from '../../../..';

type TProps = {
  index: number;
  onClick: () => void;
};

const Progress = ({index, onClick}: TProps) => {
  return (
    <Box sx={styles.container}>
      <Box sx={{flex: 1}} />
      <Box sx={styles.circleContainer}>
        <Box sx={{...styles.circle, ...(index === 1 && styles.active)} as Styles} />
        <Box sx={{...styles.circle, ...(index === 2 && styles.active)} as Styles} />
        <Box sx={{...styles.circle, ...(index === 3 && styles.active)} as Styles} />
      </Box>
      <Box sx={styles.btnView}>
        <UsuallyButton containerStyle={styles.btnStyle} rightIcon="next" onClick={onClick} />
      </Box>
    </Box>
  );
};

export default Progress;
