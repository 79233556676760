import {useEffect, useState} from 'react';
import React from 'react';
import {Container} from './styles';

type TProps = {
  isStart: boolean;
  timeCounter: number;
  onTimeEnd: () => void;
};

const TimerDiv: React.FC<TProps> = ({isStart, onTimeEnd, timeCounter}) => {
  const [seconds, setSeconds] = useState<number>(timeCounter);

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    if (isStart && seconds > 0) {
      timerId = setInterval(() => {
        setSeconds(prevSeconds => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            if (timerId) clearInterval(timerId);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [isStart, seconds]);

  useEffect(() => {
    if (seconds === 0) {
      onTimeEnd();
    }
  }, [seconds, onTimeEnd]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return <Container>{formatTime(seconds)}</Container>;
};

export default TimerDiv;
