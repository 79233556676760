import React, {useMemo} from 'react';
import {Container, Image, ImageContainer, StatusContainer, Title} from './styles';
import {EStatusFightAvatar, TProps} from './types';
import {assets} from '../../../assets';
import {ReactSVG} from 'react-svg';
import Done from '../../../assets/icons/statusDone.svg';
import {CircularProgress} from '@mui/material';

const AvatarContainer: React.FC<TProps> = ({$size, url, status, title, subTitle}) => {
  const statusComponent = useMemo(() => {
    switch (status) {
      case EStatusFightAvatar.SEARCHING:
        return <CircularProgress size={24} color={'warning'} />;
      case EStatusFightAvatar.READY:
        return <ReactSVG src={Done} />;
      default:
        return null;
    }
  }, [status]);

  return (
    <Container $size={$size}>
      <ImageContainer $size={$size}>
        {<Image src={url} alt="avatar" />}
        <StatusContainer>{statusComponent}</StatusContainer>
      </ImageContainer>
      {subTitle ? <Title>{subTitle}</Title> : null}
      {title ? <Title>{title}</Title> : null}
    </Container>
  );
};

export default AvatarContainer;
