import {Styles} from '../../../types';
import {colors} from '../../../constants';
import styled from 'styled-components';

export const styles: Styles = {
  container: {
    width: 'calc(100% - 40px)',
    paddingLeft: '20px',
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  profitPerHourView: {
    backgroundColor: colors.gray_3F3F3F,
    display: 'inline-flex',
    borderRadius: '8px',
    paddingTop: '7px',
    paddingBottom: '7px',
    paddingLeft: '13.5px',
    paddingRight: '13.5px',
    alignItems: 'center',
    marginBottom: '23px',
  },
  profitPerHourInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
  },
  profitPerHourPrice: {
    fontSize: '36px',
    lineHeight: '22px',
    color: colors.white,
    fontWeight: '700',
    letterSpacing: '-0.41px',
  },
  title: {
    fontSize: '16px',
    lineHeight: '16px',
    color: colors.white,
    fontWeight: '700',
    letterSpacing: '-0.41px',
    marginBottom: '32px',
  },
};
export const Container = styled.div`
  width: calc(100% - 40px);
  padding-left: 20px;
`;
