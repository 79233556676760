export type TContainerStyle = {
  $size: string;
};

export type TProps = TContainerStyle & {
  url?: string;
  status: EStatusFightAvatar;
  title?: string;
  subTitle?: string;
};

export enum EStatusFightAvatar {
  READY = 'READY',
  SEARCHING = 'SEARCHING',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}
