import React, {useCallback} from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import UsuallyButton from '../../navigation/UsuallyButton/UsuallyButton';
import {useNavigate} from 'react-router-dom';
import {colors} from '../../../constants';

type TProps = {};

const Support = ({}: TProps) => {
  const navigate = useNavigate();

  const handlePress = useCallback(() => {
    navigate('/coming-soon');
  }, []);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>If you have any questions, feel free to write to our support team.</Typography>
      <Box sx={styles.button}>
        <UsuallyButton
          title="Write us"
          leftIcon="pencil"
          background={`linear-gradient(90deg, ${colors.main_gradient_first_color_A92E2A} 0%, ${colors.main_gradient_second_color_4C1881} 100%)`}
          onClick={handlePress}
        />
      </Box>
    </Box>
  );
};

export default Support;
