import styled from 'styled-components';
import {colors} from '../../constants';

export const HeaderContainer = styled.div`
  margin-top: 26px;
  padding-inline: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.background};
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
`;
