import styled from 'styled-components';
import {colors} from '../../../../constants';

type TColorContainer = {
  $borderColor: string;
  $backgroundColor: string;
};

type TColor = {
  $color: string;
};
export const Container = styled.div<TColorContainer>`
  border-radius: 8px;
  border: ${({$borderColor}) => `1px solid ${$borderColor}`};
  background-color: ${({$backgroundColor}) => $backgroundColor};
  box-sizing: border-box;
  padding: 12px;
  text-align: center;
  margin-bottom: 10px;
`;

export const Text = styled.span<TColor>`
  font-size: 12px;
  font-weight: 400;
  color: ${({$color}) => $color};
`;
