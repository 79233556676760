import React, {useCallback, useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {HeaderContainer, styles} from './styles';
import {CoinsDailyReward, TotalBalance, UsuallyModal} from '../../components';
import {useAppDispatch, useAppSelector, useLazyGetRewardsQuery, useLazyUpdateDailyRewardQuery} from '../../hooks';
import {selectUser, setCoinsAction, updateUserAction} from '../../reducers/user';
import {formattedNumberByСards} from '../../helpers';
import {ReactSVG} from 'react-svg';
import CoinSVG from '../../assets/icons/coin.svg';
import {
  fetchAndSetRewardAction,
  selectReward,
  setCurrentUserRewardAction,
  setRewardAction,
} from '../../reducers/reward';
import {TReward} from '../../types/entities';
import {Styles} from '../../types';

const DailyRewards = () => {
  const {data: user} = useAppSelector(selectUser);
  const {data, index, isGetReward} = useAppSelector(selectReward);
  const dispatch = useAppDispatch();
  const [updateDailyRewardQuery] = useLazyUpdateDailyRewardQuery();
  const [getRewardsQuery] = useLazyGetRewardsQuery();
  const [isVisibleModal, seIsVisibleModal] = useState(false);
  const [newReward, setNewReward] = useState<undefined | TReward>();

  if (!user) return <></>;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (user) {
      try {
        const {data} = await getRewardsQuery({user_tg_id: user.user_tg_id});
        if (data) {
          dispatch(updateUserAction({server_time: data.server_time}));
          dispatch(setRewardAction(data));
          dispatch(fetchAndSetRewardAction());
        }
      } catch (error) {}
    }
  };

  const onChange = useCallback(
    async (item: TReward, i: number) => {
      if (isGetReward && i === index) {
        try {
          const {data: d} = await updateDailyRewardQuery({
            userId: user.id,
            daily_reward_id: item.id,
          });
          dispatch(setCurrentUserRewardAction(d));
          dispatch(fetchAndSetRewardAction());
          const reward = data.daily_rewards.daily_rewards.find(r => r.id === d?.daily_reward);
          if (reward) {
            dispatch(setCoinsAction(reward.coins_number));
            setNewReward(reward);
            seIsVisibleModal(true);
          }
        } catch (error) {}
      }
    },
    [data, isGetReward, index],
  );

  const handleClose = useCallback(() => {
    seIsVisibleModal(false);
  }, []);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Daily rewards</Typography>
      <HeaderContainer>
        <TotalBalance coins={user.coins_num} />
      </HeaderContainer>
      <Box sx={styles.gridContainer}>
        {data.daily_rewards.daily_rewards.map((item, i) => (
          <div key={i} onClick={() => onChange(item, i)}>
            <Box
              sx={
                {
                  ...styles.cell,
                  ...(i <= index && styles.disabledCell),
                  ...(i === index && isGetReward && styles.activeCell),
                } as Styles
              }>
              <Box sx={styles.content}>
                <Typography sx={styles.number}>Day {item.day}</Typography>
                <Box sx={styles.coinsView}>
                  <ReactSVG
                    src={CoinSVG}
                    beforeInjection={svg => {
                      svg.setAttribute('style', 'width: 20px; height: 20px; display: block; margin: auto;');
                    }}
                  />
                  <Typography sx={styles.number}>{formattedNumberByСards(item.coins_number, 0)}</Typography>
                </Box>
              </Box>
            </Box>
          </div>
        ))}
      </Box>
      <UsuallyModal isVisible={isVisibleModal && !!newReward} handleClose={handleClose}>
        {newReward ? <CoinsDailyReward reward={newReward} onApprove={handleClose} /> : null}
      </UsuallyModal>
    </Box>
  );
};

export default DailyRewards;
