import React, {useEffect, useState} from 'react';
import {Container} from './styles';

type TProps = {
  isStart: boolean;
  onTimeEnd: () => void;
  initialTime: number; // начальное время в секундах
  format: 'hh:mm' | 'mm:ss'; // формат отображения
};

const TimerDiv: React.FC<TProps> = ({isStart, onTimeEnd, initialTime, format}) => {
  const [seconds, setSeconds] = useState<number>(initialTime);

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    if (isStart && seconds > 0) {
      timerId = setInterval(() => {
        setSeconds(prevSeconds => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            if (timerId) clearInterval(timerId);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [isStart, seconds]);

  useEffect(() => {
    if (seconds === 0) {
      onTimeEnd();
    }
  }, [seconds, onTimeEnd]);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (format === 'hh:mm') {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    } else {
      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }
  };

  return <Container>{formatTime(seconds)}</Container>;
};

export default TimerDiv;
